<template>
  <div>
    <AddToStudy ref="addToStudy"/>
    <Comments ref="comments"/>
    <DownloadStudy ref="downloadStudy"/>
    <UserSettings ref="userSettingsDialog" :lockViewerOptions="true"/>
    <WorksInProgress ref="wDialog" title="Viewer"/>
    <a ref="exportLink"></a>
    <b-navbar type="dark" variant="dark">
      <b-navbar-brand class="d-none d-sm-block" href="#"><img src="../assets/logo.png" class="d-inline-block align-top" alt="Saince" height="30"></b-navbar-brand>
      <b-navbar-nav v-if="!launchedByRis">
        <b-nav-text class="fixedHeight">&nbsp;</b-nav-text>
        <b-dropdown class="ml-1" :size="buttonSize" title="Studies for Patient" :variant="(studyList.length>1)?'info':'secondary'">
          <template #button-content>
            <b-icon icon="card-list"></b-icon>
          </template>
          <b-dropdown-item v-for="(s, index) in studyList" :key="s.study_uid" @click="secondaryStudy(s.study_uid)" :disabled="s.oo">
            <span>&nbsp;{{getStudyChar(index)}}&nbsp;</span>
            {{(s.study_date_time == null) ? '---' : new Date(s.study_date_time).toLocaleString(locale)}}&nbsp;[{{s.modality.trim()}}]&nbsp;{{s.study_desc}}
          </b-dropdown-item>
          <b-dropdown-item v-if="!isMedDreamUser && (studyList.length > 1)" @click="refresh">
            <b-icon icon="arrow-clockwise"/>&nbsp;Reselect Comparison Studies
          </b-dropdown-item>
          <b-dropdown-item v-if="studyList.length==0" @click="refresh">
            <b-icon icon="arrow-clockwise"/>&nbsp;Error Loading Study List (Reload Viewer)
          </b-dropdown-item>
        </b-dropdown>
        <b-button-group class="ml-1" :size="buttonSize">
          <b-button v-if="canDownloadStudy" @click="downloadStudy" variant="secondary" title="Download Study (in zip file)">
            <b-icon icon="download"></b-icon>
          </b-button>
          <b-button class="ml-1" v-if="canUploadStudy" @click="addToStudy" variant="secondary" title="Add Objects to Study">
            <b-icon icon="paperclip"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
      <b-navbar-nav v-if="newObjectsAvailable" class="ml-2">
        <b-button-group :size="buttonSize">
          <b-button @click="refresh" variant="info" title="Refresh Viewer to Load New Objects">
            <b-icon icon="exclamation-circle-fill"/>&nbsp;<b-icon icon="arrow-clockwise"/>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-button-group class="ml-2" :size="buttonSize">
          <b-button v-if="canViewReport" @click="viewReport" :variant="(entry.report_status!='---') ? 'info' : 'secondary'" title="View Report">
            <b-icon icon="journal-medical"></b-icon>
          </b-button>
          <b-button v-if="canEditReport" @click="markRead" variant="secondary" title="Mark/Unmark Study as Read" :disabled="(entry.report_status!='---')">
            <b-icon icon="check-circle"></b-icon>
          </b-button>
        </b-button-group>
        <b-button-group class="ml-2" :size="buttonSize">
          <b-button v-if="canViewPatientHistory" :variant="(entry.ph) ? 'info' : 'secondary'" @click="viewPatientHistory" title="Patient History">
            <b-icon icon="folder2-open"></b-icon>
          </b-button>
        </b-button-group>
        <b-button-group class="ml-2" :size="buttonSize">
          <b-button @click="handleViewerLite" variant="secondary" title="View Study (Lite Viewer)">
            <b-icon icon="phone"></b-icon>
          </b-button>
          <b-button v-if="!launchedByRis" class="ml-1" @click="previousStudy" variant="secondary" title="Get Previous Study" :disabled="previousStudyUid==null">
            <b-icon icon="arrow-left-circle"></b-icon>
          </b-button>
          <b-button v-if="!launchedByRis" @click="nextStudy" variant="secondary" title="Get Next Study" :disabled="nextStudyUid==null">
            <b-icon icon="arrow-right-circle"></b-icon>
          </b-button>
          <b-button v-if="!launchedByRis" @click="handleClose" variant="secondary" title="Close Study">
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
        <b-dropdown v-if="!inViewerWindow && !launchedByRis" class="ml-2" title="User" :size="buttonSize" right>
          <template #button-content>
            <b-icon icon="person"></b-icon>
          </template>
          <b-dropdown-header>{{user}}</b-dropdown-header>
          <b-dropdown-item @click="userSettings"><b-icon icon="gear-fill"></b-icon> Settings</b-dropdown-item>
          <b-dropdown-item @click="userHistory"><b-icon icon="card-list"></b-icon> History</b-dropdown-item>
          <b-dropdown-item @click="profile"><b-icon icon="person-fill"></b-icon> Profile</b-dropdown-item>
          <b-dropdown-item @click="logout"><b-icon icon="box-arrow-right"></b-icon> Logout</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>  
</template>
<script>
import broadcast from '../common/broadcast'
import permissions from '../common/permissions'
import webServices from '../common/webServices'
import workflow from '../common/workflow'
import AddToStudy from './AddToStudy.vue'
import Comments from './Comments.vue'
import DownloadStudy from './DownloadStudy.vue'
import UserSettings from './UserSettings.vue'
import WorksInProgress from './WorksInProgress.vue'

export default {
  components: {
    AddToStudy,
    Comments,
    DownloadStudy,
    UserSettings,
    WorksInProgress
  },
  data() {
    return {
      buttonSize: "sm",
      isMedDreamUser: permissions.isMedDreamUser(),
      newObjectsAvailable: false,
      reportXML: 'TBD'
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    canDownloadStudy() {
      return permissions.hasPermission(this.entry.group, permissions.CAN_DOWNLOAD_STUDY)
    },
    canEditReport() {
      return permissions.hasPermission(this.entry.group, permissions.CAN_EDIT_REPORTS)
    },
    canUploadStudy() {
      return permissions.hasPermission(this.entry.group, permissions.CAN_UPLOAD_STUDY)
    },
    canViewPatientHistory() {
      return permissions.hasPermission(this.entry.group, permissions.CAN_VIEW_PATIENT_HISTORY)
    },
    canViewReport() {
      return permissions.hasPermission(this.entry.group, permissions.CAN_VIEW_REPORTS)
    },
    entry() {
      const entry = this.$store.getters.worklistEntryForStudy(this.studyUid)
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    exportObjs() {
      let eo = "Images"
      if (this.$store.state.exportStudy && this.$store.state.exportStudy.include_reports) {
        eo += "/Reports"
      }
      return eo
    },
    studyList() {
      return this.$store.state.viewerStudyList
    },
    selectedStudyI() {
      return this.$store.state.viewerSelectedStudyI
    },
    inViewerWindow() {
      return this.$store.state.inViewerWindow
    },
    launchedByRis() {
      return this.$store.state.launchedByRis
    },
    locale() {
      return this.$store.state.locale
    },
    openReportsInWindow() {
      return this.$store.getters.openReportsInWindow
    },
    user() {
      return this.$store.getters.user || 'User Name Not Set'
    },
    nextStudyUid() {
      return this.$store.state._nextStudyUid
    },
    previousStudyUid() {
      return this.$store.state._previousStudyUid
    },
    studyUid() {
      return this.$store.state._selectedStudyUid
    }
  },
  watch: {
    entry(newVal, oldVal) {
      this.newObjectsAvailable = false
      if (newVal.study_uid == oldVal.study_uid) {
        if ((newVal.num_series != oldVal.num_series) || (newVal.num_images != oldVal.num_images)) {
          this.newObjectsAvailable = true
        }
      }
    },
    studyList: {
      handler: function (/*val, oldVal*/) { this.$log.debug('studyList changed') },
      deep: true
    },
  },
  methods: {
    getStudyChar(studyI) {
      var code = 9676 // not selected char
      if (studyI == this.selectedStudyI) {
        code = 10026 // primary study char
      }
      else if (this.studyList[studyI].loadAsComparison) {
        code = 10112 // secondary study char base
        for (var i = 0; i < studyI; i++) {
          if (this.studyList[i].loadAsComparison) {
            code++
          }
        }
        if (code > 10132) {
          code = 10132
        }
      }
      return String.fromCharCode(code)
    },
    handleClose() {
      if (this.inViewerWindow) {
        broadcast.postMessage(broadcast.CLOSE_VIEWER_WINDOW_MSG, this.$store.state.uid)
      }
      else {
        workflow.closeStudy(this.studyUid, workflow.TARGET_VIEWER, this.$store.state.uid)
        .then(() => {
          this.$log.debug("lock for study in viewer released")
        })
        .catch(err => {
        this.$bvToast.toast(err.message, {
            autoHideDelay: 5000,
            solid: true,
            title: 'INSPIRE PACS',
            variant: 'warning'
          })
        })
        this.$router.replace(this.$store.state.prevRoute)
      }
    },
    addToStudy() {
      this.$refs.addToStudy.show(this.studyUid)
    },
    downloadStudy() {
      this.$refs['downloadStudy'].show(this.entry)
    },
    refresh() {
      if (this.checkReportIsSaved()) {
        this.newObjectsAvailable = false
        this.$store.commit("changeRefreshViewer", true)
      }
    },
    escStr(inStr) {
      return inStr.trim().replace(/[<>&'"]/g, function (c) {
        switch (c) {
            case '<': return '&lt;';
            case '>': return '&gt;';
            case '&': return '&amp;';
            case '\'': return '&apos;';
            case '"': return '&quot;';
        }
      })
    },
    handleResize() {
      this.buttonSize = (window.innerWidth < 767) ? "sm" : "md"
    },
    handleViewerLite() {
      this.$router.replace('viewer_lite')
    },
    normStr(inStr) {
      return this.escStr(inStr.replaceAll(' ', '').replaceAll('-', '').toUpperCase())
    },
    help() {
      this.$refs.wDialog.show()
    },
    checkReportIsSaved() {
      var answer = true;
      if (this.$store.getters.isReportOpenForEdit(this.$store.state.uid)) {
        answer = window.confirm('Report open in editor may have unsaved changes. Are you sure you want to close report?')
        if (answer && (this.$store.state.activeComponent == 'ReportEditor')) {
          this.$store.commit('changeActiveStudyUid', '')
          this.$store.commit('changeActiveComponent', '')
        }
      }
      return answer
    },
    displayToast(message, variant) {
      this.$bvToast.toast(message, {
        autoHideDelay: 2000,
        solid: true,
        title: 'INSPIRE PACS',
        toaster: 'b-toaster-top-left',
        variant: variant,
      })
    },
    markRead() {
      const state = (this.entry.read) ? 'unread' : 'read'
      webServices.updateStudyRead(this.entry, state)
      .then(response => {
        if (response.result == 'OK') {
          if (this.inViewerWindow) {
            broadcast.postMessage(broadcast.STUDY_UPDATED_MSG, this.$store.state.uid)
          }
          this.entry.read = response.read
          this.entry._rowVariant = webServices.reportStatusToVariant(this.entry.report_status, this.entry.read, this.entry.stat)
          let toastMsg = "[" + webServices.getTitleForEntry(this.entry) + "] marked "
          toastMsg += (this.entry.read) ? " READ" : " UNREAD"
          this.displayToast(toastMsg, 'success')
        }
        else {
          const toastMsg = response.result + " [" + webServices.getTitleForEntry(this.entry) + "]"
          this.displayToast(toastMsg, 'warning')
        }
      })
      .catch(err => {
        this.$log.error("Error updating read setting for study: "+err)
      })
    },
    secondaryStudy(studyUid) {
      if (studyUid == this.studyUid) {
        return;
      }
      let route = 'viewer'
      let secondaryEntry = null
      for (var s = 0; s < this.studyList.length; s++) {
        if (this.studyList[s].study_uid == studyUid) {
          secondaryEntry = this.studyList[s]
          break;
        }
      }
      if (secondaryEntry !== null) {
        this.$log.debug(`Requesting secondary viewer window for studyUid=${studyUid}`)
        if (this.inViewerWindow) {
          // Let primary window handle opening viewer window.
          //
          broadcast.postMessage(broadcast.OPEN_VIEWER_WINDOW_MSG, {
            'entry': secondaryEntry,
            'route': route,
            'studyUid': studyUid
          })
        }
        else {
          this.$store.commit('addSecondaryWorklistEntry', secondaryEntry)
          workflow.openSecondaryViewer(route, studyUid)
        }
      }
      else {
        this.$log.warn(`Secondary entry not found for studyUid=${studyUid}`)
      }
    },
    nextStudy() {
      var nextStudyUid = this.nextStudyUid
      var windowUid = this.$store.state.uid
      if (nextStudyUid != null) {
        if (this.checkReportIsSaved()) {
          if (this.inViewerWindow) {
            broadcast.postMessage(broadcast.NEXT_STUDY_UID_MSG, windowUid)
          }
          else {
            workflow.closeStudy(this.studyUid, workflow.TARGET_VIEWER, windowUid)
            .then(() => {
              this.$log.debug("lock for study in viewer released")
            })
            .catch(err => {
              this.$bvToast.toast(err.message, {
                autoHideDelay: 5000,
                solid: true,
                title: 'INSPIRE PACS',
                variant: 'warning'
              })
            })
            .finally(() => {
              workflow.openStudy(nextStudyUid, workflow.TARGET_VIEWER, windowUid)
              .then(() => {
                this.$store.commit("changeSelectedStudyUids", nextStudyUid)
                if ((this.$store.state.reportWindows[windowUid] !== undefined) && !this.$store.state.reportWindows[windowUid].closed) {
                  workflow.openStudy(nextStudyUid, workflow.TARGET_REPORT_WINDOW, windowUid)
                  .then(() => {
                    broadcast.postSelectedStudy(workflow.TARGET_REPORT_WINDOW, windowUid)
                  })
                }
              })
              .catch(err => {
                this.$bvToast.toast(err.message, {
                    autoHideDelay: 5000,
                    solid: true,
                    title: 'INSPIRE PACS',
                    variant: 'warning'
                })
              })
            })
          }
        }
      }
    },
    previousStudy() {
      var previousStudyUid = this.previousStudyUid
      var windowUid = this.$store.state.uid
      if (previousStudyUid != null) {
        if (this.checkReportIsSaved()) {
          if (this.inViewerWindow) {
            broadcast.postMessage(broadcast.PREV_STUDY_UID_MSG, windowUid)
          }
          else {
            workflow.closeStudy(this.studyUid, workflow.TARGET_VIEWER, windowUid)
            .then(() => {
              this.$log.debug("lock for study in viewer released")
            })
            .catch(err => {
              this.$bvToast.toast(err.message, {
                autoHideDelay: 5000,
                solid: true,
                title: 'INSPIRE PACS',
                variant: 'warning'
              })
            })
            .finally(() => {
              workflow.openStudy(previousStudyUid, workflow.TARGET_VIEWER, windowUid)
              .then(() => {
                this.$store.commit("changeSelectedStudyUids", previousStudyUid)
                if ((this.$store.state.reportWindows[windowUid] !== undefined) && !this.$store.state.reportWindows[windowUid].closed) {
                  workflow.openStudy(previousStudyUid, workflow.TARGET_REPORT_WINDOW, windowUid)
                  .then(() => {
                    broadcast.postSelectedStudy(workflow.TARGET_REPORT_WINDOW, windowUid)
                  })
                }
              })
              .catch(err => {
                this.$bvToast.toast(err.message, {
                    autoHideDelay: 5000,
                    solid: true,
                    title: 'INSPIRE PACS',
                    variant: 'warning'
                })
              })
            })
          }
        }
      }
    },
    logout() {
      this.$router.replace('logout')
    },
    userHistory() {
      var answer = true
      if (this.$store.state.activeComponent == 'ReportEditor') {
        answer = window.confirm('Report open in editor may have unsaved changes. Are you sure you want to close report?')
      }
      if (answer) {
        this.$store.commit('changeActiveComponent', 'UserHistory')
      }
    },
    userSettings() {
      this.$refs.userSettingsDialog.show()
    },
    profile() {
      try {
        window.open(this.$store.state.keycloak.createAccountUrl());
      }
      catch(err) {
        this.$log.error('Failed to open account profile: '+err);
      }
    },
    viewPatientHistory() {
      // If patient history is already open, then close.
      //
      if ((this.$store.state.activeComponent == 'PatientHistory') ||
          (this.$store.state.activeComponent == 'ImageViewer') ||
          (this.$store.state.activeComponent == 'PdfViewer')) {
        this.$store.commit('changeActiveStudyUid', '')
        this.$store.commit('changeActiveComponent', '')
      }
      else if (this.checkReportIsSaved()) {
        this.$log.debug("updating activeStudyUid to null")
        this.$store.commit('changeActiveComponent', 'PatientHistory')
        this.$store.commit('changeActiveStudyUid', '')
        this.$store.commit('changeActiveStudyUid', this.studyUid)
      }
    },
    viewReport() {
      // Avoid interactions for RIS integration to ignore user setting for reports in window.
      //
      if (this.openReportsInWindow && !this.launchedByRis) {
        const reportWindowUid = this.$store.state.uid
        if ((this.$store.state.activeComponent == 'ReportEditor') || (this.$store.state.activeComponent == 'ReportViewer')) {
          this.$store.commit('changeActiveComponent', '')
          this.$store.commit('changeActiveStudyUid', '')
        }
        if (this.inViewerWindow) {
          // Let primary window handle opening report window.
          //
          broadcast.postMessage(broadcast.OPEN_REPORT_WINDOW_MSG, {
            'group': this.entry.group,
            'studyUid': this.studyUid,
            'windowUid': reportWindowUid
          })
        } 
        else if ((this.$store.state.reportWindows[reportWindowUid] === undefined) || (this.$store.state.reportWindows[reportWindowUid].closed)) {
          let payload = {
            'studyUid': this.studyUid,
            'windowUid': reportWindowUid
          }
          this.$store.commit("changeSelectedStudyUids", payload)
          var box = this.$store.state.reportWindowBox
          const windowOpts = 'popup=1,left='+box.x+',top='+box.y+',height='+box.h+',width='+box.w
          let reportWindowName = 'saincepacs_report_'+reportWindowUid
          let reportWindow = window.open('/#/report?uid='+encodeURIComponent(reportWindowUid), reportWindowName, windowOpts)
          this.$store.commit('changeReportWindows', {
            'window': reportWindow, 
            'windowUid': reportWindowUid
          })
        }
        else {
          this.$store.state.reportWindows[reportWindowUid].focus()
          workflow.openStudy(this.studyUid, workflow.TARGET_REPORT_WINDOW, reportWindowUid)
          .then(() => {
            broadcast.postSelectedStudy(workflow.TARGET_REPORT_WINDOW, reportWindowUid)
          })
          .catch(() => {
            this.$log.error("Unable to open study for report window")
          })
        }
      }
      else {
        // If report viewer or editor already open, then close.
        //
        if (this.$store.state.activeComponent == 'ReportViewer') {
          this.$store.commit('changeActiveStudyUid', '')
          this.$store.commit('changeActiveComponent', '')
        }
        else if (this.$store.state.activeComponent == 'ReportEditor') {
          if (this.checkReportIsSaved()) {
            this.$store.commit('changeActiveStudyUid', '')
            this.$store.commit('changeActiveComponent', '')
          }
        }
        else {
          this.$store.commit('changeActiveComponent', 'ReportViewer')
          this.$store.commit('changeActiveStudyUid', '')
          this.$store.commit('changeActiveStudyUid', this.studyUid)
        }
      }
    }
  }
}    
</script>
<style scoped>
.fixedHeight {
  height: 36px;
  min-height: 36px;
}
</style>